import { css } from 'styled-components';

import styling from '../constants/styling';

import neueDisplay55w2 from './fonts/Neue-Haas-Grotesk-Display-55.woff2';
import neueDisplay55w from './fonts/Neue-Haas-Grotesk-Display-55.woff';
import neueDisplay65w2 from './fonts/Neue-Haas-Grotesk-Display-65.woff2';
import neueDisplay65w from './fonts/Neue-Haas-Grotesk-Display-65.woff';
import neueText75w2 from './fonts/Neue-Haas-Grotesk-Text-75.woff2';
import neueText75w from './fonts/Neue-Haas-Grotesk-Text-75.woff';
import copernicusItalicW2 from './fonts/Copernicus-BookItalic.woff2';
import copernicusItalicW from './fonts/Copernicus-BookItalic.woff';
import copernicusW2 from './fonts/Copernicus-Book.woff2';
import copernicusW from './fonts/Copernicus-Book.woff';
import axiforma from './fonts/AxiformaBlack.otf';

const typography = css`
  @font-face {
    font-family: '${styling.fontNameAxiforma}';
    src: url('${axiforma}') format("opentype");
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: '${styling.fontNameCopernicus}';
    src: url('${copernicusW2}') format('woff2'), url('${copernicusW}') format('woff');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: '${styling.fontNameCopernicus}';
    src:  url('.${copernicusItalicW2}') format('woff2'), url('.${copernicusItalicW}') format('woff');
    font-style: italic;
    font-weight: normal;
  }

  @font-face {
    font-family: '${styling.fontNameNeueHaasDisplay}';
    src:  url('${neueDisplay55w2}') format('woff2'), url('${neueDisplay55w}') format('woff');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: '${styling.fontNameNeueHaasDisplay}';
    src:  url('${neueDisplay65w2}') format('woff2'), url('${neueDisplay65w}') format('woff');
    font-style: normal;
    font-weight: bold;
  }

  @font-face {
    font-family: '${styling.fontNameNeueHaasText}';
    src:  url('${neueText75w2}') format('woff2'), url('${neueText75w}') format('woff');
    font-style: normal;
    font-weight: bold;
  }

  body {
    font-family: ${styling.fontFamilyNeueHaasDisplay};
  }

  h1, h2, h3, h4, h5 {
    font-family: ${styling.fontFamilyNeueHaasDisplay};
    font-weight: bold;
  }

  h6 {
    font-family: ${styling.fontFamilyCopernicus};
    font-style: italic;
    font-weight: normal;
  }
`;

export default typography;
