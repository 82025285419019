/* eslint-disable react/no-unused-state */

import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';

import testForPassiveScroll from './testForPassiveScroll';

export const ViewportContext = createContext({
  windowWidth: 0,
  windowHeight: 0,
  scrollX: 0,
  scrollY: 0,
});

export default class ViewportProvider extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      windowWidth: 0,
      windowHeight: 0,
      scrollX: 0,
      scrollY: 0,
    };

    // Passive support
    this.supportsPassive = testForPassiveScroll();

    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = throttle(this.handleResize.bind(this), 100);
  }

  componentWillMount() {
    // Don't initialize on the server
    const isServer = typeof window === 'undefined';

    if (!isServer) {
      // Must not be the server so kick it off...
      window.addEventListener(
        'scroll',
        this.handleScroll,
        this.supportsPassive ? { passive: true } : false,
      );
      window.addEventListener('resize', this.handleResize, false);

      // Trigger the inital resize event.
      if (document.readyState === 'complete') {
        this.handleResize();
      } else {
        window.addEventListener('load', this.handleResize);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      'scroll',
      this.handleScroll,
      this.supportsPassive ? { passive: true } : false,
    );
    window.removeEventListener('resize', this.handleResize, false);
    window.removeEventListener('load', this.handleResize);
  }

  getScrollPosition() {
    return {
      x: Math.max(0, window.pageXOffset),
      y: Math.max(0, window.pageYOffset),
    };
  }

  /**
   * Window scroll handler. Sets the 'scrollY'
   * and then calls '_updateElementPositions()'.
   */
  handleScroll() {
    // direction
    // scrollDown = scrollY > prevScrollY;

    // Supports IE 9 and up.
    window.requestAnimationFrame(() => {
      const scrollPos = this.getScrollPosition();
      this.setState({
        scrollX: scrollPos.x,
        scrollY: scrollPos.y,
      });
    });
  }

  /**
   * Window resize handler. Sets the new window inner height
   * then updates parallax element attributes and positions.
   */
  handleResize() {
    /**
     * Cache the window height.
     */
    const html = document.documentElement;
    const scrollPos = this.getScrollPosition();
    this.setState({
      windowHeight: window.innerHeight || html.clientHeight,
      windowWidth: window.innerWidth || html.clientWidth,
      scrollX: scrollPos.x,
      scrollY: scrollPos.y,
    });
  }

  render() {
    const { children } = this.props;

    return (
      <ViewportContext.Provider value={this.state}>
        {children}
      </ViewportContext.Provider>
    );
  }
}
