import styled from 'styled-components';
import breakpoint from '../constants/breakpoints';

export const h2 = styled.h2`
  font-size: 38px;

  @media (min-width: ${breakpoint.lg}px) {
    font-size: 60px;
  }
`;

export const h3 = styled.h3`
  font-size: 32px;
  line-height: 1.1;

  @media (min-width: ${breakpoint.lg}px) {
    font-size: 48px;
  }
`;

export const h4 = styled.h4`
  font-size: 28px;
  line-height: 1.6;

  @media (min-width: ${breakpoint.lg}px) {
    font-size: 36px;
  }
`;

export const h5 = styled.h5`
  color: #000000;
  font-size: 18px;

  @media (min-width: ${breakpoint.lg}px) {
    font-size: 22px;
  }
`;

export const h6 = styled.h6`
  font-size: 18px;
  line-height: 1.6;

  @media (min-width: ${breakpoint.lg}px) {
    font-size: 22px;
  }
`;

export const p1 = styled.p`
  font-size: 14px;
  line-height: 1.7;

  @media (min-width: ${breakpoint.lg}px) {
    font-size: 16px;
  }
`;

export const p2 = styled.p`
  font-size: 14px;
  line-height: 1.7;
`;

export const p3 = styled.span`
  font-size: 10px;
  line-height: 2.4;
  font-weight: bold;

  @media (min-width: ${breakpoint.lg}px) {
    font-size: 18px;
  }
`;
