import GatsbyLink from 'gatsby-link';
import React from 'react';
import PropTypes from 'prop-types';

// const Link = (props) => {
//     const internal = /^\/(?!\/)/.test(props.to);
//     if (internal) {
//         return (
//             <GatsbyLink to={to} {...other}>
//                 {children}
//             </GatsbyLink>
//         );
//     }
//     return (
//         <a href={to} {...other}>
//             {children}
//         </a>
//     );
// };

export default class extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    to: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    to: '',
  };

  render() {
    const { children, to, ...other } = this.props;
    const internal = /^\/(?!\/)/.test(to);
    if (internal) {
      return (
        <GatsbyLink to={to} {...other}>
          {children}
        </GatsbyLink>
      );
    }
    return (
      <a href={to} {...other}>
        {children}
      </a>
    );
  }
}
