// const fontRatio = 1.333;
// const jumboFontSize = fontRatio ** 5;
// const h1FontSize = fontRatio ** 3.75;
// const h2FontSize = fontRatio ** 3;
// const h3FontSize = fontRatio ** 2;
// const h4FontSize = fontRatio ** 1;

export default {
  white: '#fff',
  black: '#000',
  blue: '#4a76e4',
  gray: '#adadad',

  fontNameCopernicus: 'Copernicus',
  fontFamilyCopernicus: '"Copernicus",serif',

  fontNameNeueHaasDisplay: 'NeueHaasGroteskDisplay',
  fontFamilyNeueHaasDisplay:
    '"NeueHaasGroteskDisplay","Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif',

  fontNameNeueHaasText: 'NeueHaasGroteskText',
  fontFamilyNeueHaasText:
    '"NeueHaasGroteskText","Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif',

  fontNameAxiforma: 'Axiforma',
  fontFamilyAxiforma:
    '"Axiforma","Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif',
};

export const zIndex = {
  nav: 1,
  overlay: 2,
};
