export const minWidth = 375;

export default {
  xs: 0,
  sm: 576, // phone
  md: 768, // tablet
  lg: 992, // desktop
  xl: 1200,
  xxl: 1400, // large
};
