import React from 'react';
import styled from 'styled-components';
import { h5, h6 } from '../components/typographyComponents';
import Link from '../components/link';
import Layout from '../layouts/index';

const PnfContainer = styled.section`
  width: 100%;
  height: 99vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PnfTitle = styled.h1`
  font-size: 12vw;
`;

const PnfSubtitle = styled(h6)`
  font-size: 3vw;
`;

const PnfButtonText = styled(h5)`
  color: #fff;
`;

const PnfHomeButton = styled.div`
  background-color: #4a76e4;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 42px;
`;

const NotFoundPage = () => (
  <Layout>
    <PnfContainer>
      <PnfTitle>404</PnfTitle>
      <PnfSubtitle>Nothing to see here...</PnfSubtitle>
      <Link href="/">
        <PnfHomeButton>
          <PnfButtonText>Take me back!</PnfButtonText>
        </PnfHomeButton>
      </Link>
    </PnfContainer>
  </Layout>
);

export default NotFoundPage;
