import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { createGlobalStyle } from 'styled-components';

import normalized from '../styling/normalized';
import scaffolding from '../styling/scaffolding';
import typography from '../styling/typography';
import ViewportProvider from '../utils/ViewportProvider';

export default class Layout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const GlobalStyle = createGlobalStyle`
      ${normalized}
      ${typography}
      ${scaffolding}
    `;

    const { children } = this.props; // modalOpen
    return (
      <ViewportProvider>
        <GlobalStyle />
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />

          <title>Newborn - Accelerating Innovation - Powered by Ace</title>
          <link rel="canonical" href="https://newborn.ventures/" />
          <meta
            property="og:title"
            content="Newborn - Accelerating Innovation"
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="http://www.newborn.investments" />
          <meta
            property="og:image"
            content="http://www.newborn.investments/social-share.jpg?v=2"
          />
          <meta
            property="og:site_name"
            content="Newborn - Accelerating Innovation"
          />
          <meta
            property="og:description"
            content="Ace launches Newborn, to collaborate with startups and to accelerate innovation. A new initiative that unites the outspoken DNA of a startup with the proven track record and experience of an award-winning agency."
          />
          <meta property="og:locale" content="en_US" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@born05" />
          <meta
            name="twitter:title"
            content="Newborn - Accelerating Innovation"
          />
          <meta
            name="twitter:description"
            content="Ace launches Newborn, to collaborate with startups and to accelerate innovation."
          />
          <meta
            name="twitter:image"
            content="http://www.newborn.investments/social-share.jpg?v=2"
          />
          <meta name="twitter:url" content="http://www.newborn.investments" />

          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#4a76e4" />
          <meta name="msapplication-TileColor" content="#000000" />
          <meta name="theme-color" content="#000000" />
        </Helmet>

        <div>{children}</div>
      </ViewportProvider>
    );
  }
}
